import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

export const ContactInfo = () => {
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  const [isLinkedInHovered, setIsLinkedInHovered] = useState(false);

  const emailStyle = {
    textDecoration: 'none',
    color: isEmailHovered ? 'antiquewhite' : 'white', // Change color on hover
  };

  const linkedInStyle = {
    textDecoration: 'none',
    color: isLinkedInHovered ? 'antiquewhite' : 'white', // Change color on hover
  };

  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='h5'
        sx={{
          textShadow: '2px 2px 4px #000000',
          fontWeight: 'bold',
          mb: '6%',
          fontFamily: 'Georgia, serif',
        }}
      >
        Contact Info
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <EmailRoundedIcon color='primary' />
          <Typography sx={{ fontFamily: 'Georgia, serif', mb: '3%', ml: '2%' }}>
            <a
              href='mailto:zixiwangcecilia@gmail.com'
              style={emailStyle}
              onMouseEnter={() => setIsEmailHovered(true)}
              onMouseLeave={() => setIsEmailHovered(false)}
            >
              zixiwangcecilia@gmail.com
            </a>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <LinkedInIcon color='primary' />
          <Typography sx={{ fontFamily: 'Georgia, serif', mb: '3%', ml: '2%' }}>
            <a
              className='email-text'
              href='https://www.linkedin.com/in/zixi-wang-cecilia/'
              target='_blank'
              style={linkedInStyle}
              onMouseEnter={() => setIsLinkedInHovered(true)}
              onMouseLeave={() => setIsLinkedInHovered(false)}
            >
              www.linkedin.com/in/zixi-wang-cecilia/
            </a>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          mt: '10%',
        }}
      >
        <Box
          component='img'
          src='/img/wechat.jpg'
          alt='WeChat QR'
          sx={{
            height: '150px',
            width: '150px',
            borderRadius: '10px',
          }}
        />
        <Typography sx={{ fontFamily: 'Georgia, serif', mt: '3%' }}>
          Scan to connect via WeChat
        </Typography>
      </Box>
    </Grid>
  );
};
