import { Box, Grid } from '@mui/material';
import { ProjectItem } from '../../const/ProjectItem';
import { Tag } from '../../const/Tag';
import { ProjectCard } from '../projectsPage/ProjectCard';

export const ProjectsContent = ({
  projects,
  addTag,
}: {
  projects: ProjectItem[];
  addTag: (tag: Tag) => void;
}) => {
  return (
    <Box
      className='projects-container'
      sx={{
        // backgroundImage: 'url("/bg/homepage-1.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          padding: '5%',
        }}
      >
        <Grid
          container
          spacing={{ xs: 3, md: 8 }}
          columns={{ xs: 3.5, sm: 8, md: 12 }}
          className='project-cards-container'
        >
          {projects.map((projectItem: ProjectItem) => (
            <Grid
              item
              xs={3}
              sm={4}
              md={4}
              key={`project-card-container-${projectItem.title}`}
              className='project-card-container'
            >
              <ProjectCard project={projectItem} addTag={addTag} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
