import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import axios from 'axios';
import { SERVER_URL } from '../const/MacroConst';
import { ProjectItem } from '../const/ProjectItem';
import '../css/projectsPage/ProjectsPage.css';
import { Tag } from '../const/Tag';
import { projectsPageTheme } from '../themes/themes';
import { useLocation } from 'react-router-dom';
import { ProjectsContent } from '../components/projectsPage/ProjectContent';

const ProjectsPage = ({
  searchQuery,
  setSearchQuery,
  activeTags,
  setActiveTags,
  handleClickTag,
}: {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  activeTags: Tag[];
  setActiveTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  handleClickTag: React.Dispatch<React.SetStateAction<Tag>>;
}) => {
  const [projects, setProjects] = useState<ProjectItem[]>([]);
  const location = useLocation();

  const fetchProjectData = (params?: URLSearchParams) => {
    axios
      .get(`${SERVER_URL}/api/projects/?${params ? params.toString() : ''}`)
      .then((response) => {
        console.log('response.data', response.data);
        setProjects(response.data);
      })
      .catch((error) => console.error('Error fetching projects:', error));
  };

  useEffect(() => {
    const params = new URLSearchParams({
      search: searchQuery,
      tags: activeTags.map((tag) => tag.name).join(','),
    });

    fetchProjectData(params);
  }, [searchQuery, activeTags]);

  // Reset searchQuery and activeTags when the Projects page is revisited
  useEffect(() => {
    if (location.pathname === '/projects') {
      setSearchQuery('');
      setActiveTags([]);
      fetchProjectData();
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={projectsPageTheme}>
      <ProjectsContent projects={projects} addTag={handleClickTag} />
    </ThemeProvider>
  );
};

export default ProjectsPage;
