import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { homePageTheme } from '../themes/themes';
import { CONTACT_FORM_URL } from '../const/MacroConst';
import '../css/contactsPage/contactsPage.css';
import { ContactForm } from '../components/contactsPage/ContactForm';
import { ContactInfo } from '../components/contactsPage/ContactInfo';

const ContactsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const response = await fetch(CONTACT_FORM_URL, {
      redirect: 'follow', // important, as google script sends two requests, the second one is a GET method. If keep using POST the server will error with 405.
      method: 'POST',
      headers: { 'Content-Type': 'text/plain;charset=utf-8' },
      body: JSON.stringify(formData),
    });
    const result = await response.json();
    if (result.status === 'success') {
      alert('Message sent successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } else {
      alert('Failed to send message.');
    }
  };

  return (
    <ThemeProvider theme={homePageTheme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundImage: 'url("/bg/homepage-1.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Title />
        {/* Content */}
        <Grid
          container
          sx={{
            width: {
              xs: '90%',
              sm: '80%',
              md: '80%',
            },
            justifyContent: 'space-around', // Adjusts spacing between columns
          }}
          data-aos='fade-up'
          data-aos-easing='linear'
        >
          <ContactInfo />
          <ContactForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

const Title = () => {
  return (
    <Typography
      variant='h2'
      sx={{
        fontFamily: 'Georgia, serif',
        fontWeight: 'bold',
        textShadow: '2px 2px 4px #000000',
        textAlign: 'center',
        mb: '4%',
        fontSize: {
          xs: '3rem', // Mobile screens
          sm: '3.5rem', // Tablet screens
          md: '4.5rem', // Larger screens
        },
        marginTop: {
          xs: '10%',
          sm: '8%',
        },
      }}
      color='textPrimary'
      data-aos='fade-down'
      data-aos-easing='linear'
    >
      Get in Touch
    </Typography>
  );
};

export default ContactsPage;
