import { Box, Button, Grid, TextField } from '@mui/material';

export const ContactForm = ({
  formData,
  handleChange,
  handleSubmit,
}: {
  formData: any;
  handleChange: any;
  handleSubmit: any;
}) => {
  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{
        marginBottom: {
          xs: '10%',
          sm: '8%',
        },
        marginTop: {
          xs: '10%',
          sm: '5%',
          md: '0',
        },
      }}
    >
      <Box
        component='form'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          bgcolor: 'rgba(255, 255, 255, 0.1)',
          padding: '4%',
          borderRadius: '10px',
          backdropFilter: 'blur(4px)',
          maxWidth: '400px', // Controls the form width
          margin: '0 auto', // Centers the form horizontally
        }}
        onSubmit={handleSubmit} // Added form submission handler here
      >
        <TextField
          label='Your Name'
          variant='outlined'
          fullWidth
          required
          name='name'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label='Your Email'
          variant='outlined'
          fullWidth
          required
          name='email'
          type='email'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label='Subject'
          variant='outlined'
          fullWidth
          required
          name='subject'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.subject}
          onChange={handleChange}
        />
        <TextField
          label='Message'
          variant='outlined'
          multiline
          rows={4}
          fullWidth
          required
          name='message'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.message}
          onChange={handleChange}
        />
        <Button
          type='submit'
          variant='contained'
          sx={{
            'backgroundColor': 'primary',
            'fontWeight': 'bold',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
            'fontFamily': 'Georgia, serif',
          }}
        >
          Send Message
        </Button>
      </Box>
    </Grid>
  );
};
