import React, { Dispatch, SetStateAction } from 'react';
import { TypingIntro } from '../components/homePage/TypingIntro';
import { ThemeProvider } from '@mui/material/styles';
import { homePageTheme } from '../themes/themes';
import { Avatar, Box, Button, Container, Typography } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import '../css/homePage/HomePage.css';
import { ArrowForward } from '@mui/icons-material';
import StarField from '../components/homePage/StarField';
import { PageWithNavBar } from '../components/navBar/NavBar';
import AOS from 'aos';
import { SERVER_URL } from '../const/MacroConst';

const HomePage = ({
  show,
  setShow,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <ThemeProvider theme={homePageTheme}>
      <div style={{ backgroundColor: 'black' }}>
        <CssForTypingIntro show={show} setShow={setShow} />
        <CssForMainComponent show={show} />
      </div>
    </ThemeProvider>
  );
};

const CssForTypingIntro = ({
  show,
  setShow,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <CSSTransition in={!show} timeout={1000} classNames='fade' unmountOnExit>
      <TypingIntro setShow={setShow} />
    </CSSTransition>
  );
};

const CssForMainComponent = ({ show }: { show: boolean }) => {
  return (
    <CSSTransition
      in={show}
      timeout={2000}
      classNames='fade'
      unmountOnExit
      onEntered={() => AOS.refresh()} // Refresh AOS
    >
      <PageWithNavBar children={<MainComponent />} showSearchBar={false} />
    </CSSTransition>
  );
};

const Profile = () => {
  return (
    <Container
      className='home-profile-container'
      sx={{ display: 'flex' }} // don't delete this line...
      data-aos='fade-down'
      data-aos-easing='linear'
    >
      <Avatar
        alt='Cat!'
        src='/img/cat.png'
        className='home-profile-avatar'
        sx={{ width: 200, height: 200 }}
      />
      <Container className='text-align-center font-georgia-serif'>
        <Typography
          variant='h6'
          fontFamily='Georgia, serif'
          color='textPrimary'
        >
          Final Year Computing Student @ Imperial College
        </Typography>
        <Typography
          variant='h2'
          fontFamily='Georgia, serif'
          sx={{
            marginBottom: '1%',
            fontWeight: 'bold',
            textShadow: '2px 2px 4px #000000',
          }}
          color='textPrimary'
        >
          Zixi Wang
        </Typography>
        <Typography
          variant='body1'
          fontFamily='Georgia, serif'
          sx={{
            textShadow: '1px 1px 2px #000000',
          }}
          color='textSecondary'
        >
          "Your best and wisest refuge from all troubles is in your science."
        </Typography>
      </Container>
      <Button href={`${SERVER_URL}/api/cv`} download endIcon={<ArrowForward />}>
        Download Resume
      </Button>
    </Container>
  );
};

const MainComponent = () => {
  return (
    <Box
      className='main-component'
      sx={{
        backgroundImage: 'url("/bg/homepage-1.png")',
      }}
    >
      <StarField />
      <Profile />
    </Box>
  );
};

export default HomePage;
