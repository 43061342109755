import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, Chip } from '@mui/material';
import { ProjectItem, ProjectTag } from '../../const/ProjectItem';
import '../../css/projectsPage/ProjectCard.css';
import { Tag } from '../../const/Tag';
import { useNavigate } from 'react-router-dom';

export const ProjectCard = ({
  project,
  addTag,
}: {
  project: ProjectItem;
  addTag: (tag: Tag) => void;
}) => {
  const navigate = useNavigate();

  const handleClickCard = () => {
    if (!project.detailed_project_id) return;
    navigate(`/project/${project.title}`);
  };

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '2%',
        bgcolor: 'rgb(255, 255, 255, 0.1)',
        backdropFilter: 'blur(4px)',
      }}
      data-aos='fade-up'
      data-aos-easing='linear'
      className='project-card'
    >
      <CardActionArea onClick={handleClickCard}>
        <CardMedia
          component='img'
          height='220'
          image={project.image}
          alt={project.title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant='h5'
            component='div'
            sx={{ textShadow: '2px 2px 4px #000000', fontWeight: 'bold' }}
            fontFamily='Georgia, serif'
          >
            {project.title}
          </Typography>
          <Typography
            gutterBottom
            variant='body2'
            component='div'
            sx={{ textShadow: '2px 2px 4px #000000' }}
            fontFamily='URW Chancery L, cursive'
            color='primary'
            fontWeight='bold'
          >
            {project.time}
          </Typography>
          <Typography
            variant='body2'
            color='text.secondary'
            height='120px'
            sx={{
              'textShadow': '1px 1px 2px #000000',
              'overflow': 'auto', // Make sure it's scrollable
              'scrollbarWidth': 'none', // Firefox hides scrollbar
              '&::-webkit-scrollbar': {
                display: 'none', // Chrome, Safari, Opera
              },
            }}
            fontFamily='Georgia, serif'
          >
            {project.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          '& .MuiChip-root:first-of-type': {
            marginLeft: '8px', // Add the default marginLeft to the first Chip as well
          },
          'overflow': 'auto',
          'scrollbarWidth': 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        className='project-card-actions'
      >
        {project.tags.map((tag: ProjectTag) => (
          <Chip
            label={tag.name}
            variant='outlined'
            sx={{
              color: tag.color,
              borderColor: tag.color,
              fontSize: 'small',
              borderStyle: 'dashed',
            }}
            onClick={() => addTag(tag)}
            key={`project-card-action=${tag.name}`}
          />
        ))}
      </CardActions>
    </Card>
  );
};
